
<style lang="less" scoped>
.null_box {
    height: 100vh;
    display: flex;
    justify-content: center;
}
</style>
<template>
    <div class="null_box" v-if="!releaseId">
        <el-empty description="亲~你的视频库视频木有啦!"></el-empty>
    </div>
</template>

<script>
import {
    getPublishTaskList,
    getPublishLink,
    getPublishList,
} from "@/api/publish";
export default {
    name: "Getpiblish",

    data() {
        return {
            releaseId: true,
        };
    },

    mounted() {
        let groupId = this.$route.query.groupId;
        let num = 1;

        getPublishList({ groupId, num }).then((res) => {
            if (res.code == 200) {
                console.log(res);
                console.log(res.data.length);
                if (res.data.length == 0) {
                    this.releaseId = false;
                    return;
                }
                let releaseId = res.data[0].releaseId;
                this.releaseId = releaseId;
                console.log("获取发布链接");
                getPublishLink({ releaseId }).then((res) => {
                    if (res.code == 200) {
                        this.qr_url = res.msg;
                        location.href = res.msg;
                    } else {
                        this.$message.error("获取发布视频链接失败！");
                    }
                });
            } else {
                this.$message.error("获取发布视频失败！");
            }
        });
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
</style>